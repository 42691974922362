
import { createStore } from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

import moduleLayout from "./modules/layout.js"
import moduleUsers from './modules/users.js'
import moduleNotifications from "./modules/notifications.js"
import moduleApiKey from './modules/api-keys.js'
import moduleProgram from './modules/programs.js'

const store = createStore({
    state,
    actions,
    getters,
    mutations,
    modules: {
        layout: moduleLayout,
        userList: moduleUsers,
        notification: moduleNotifications,
        apiKey: moduleApiKey,
        program: moduleProgram
    },
    strict: process.env.NODE_ENV !== 'production',
})

export default store
